/**
 * return function for easily formatting all numbers into correct currency
 * @param {string} currency 3-digit code for proper currency
 * @param {number} minimumFractionDigits
 * @return {function(number|string): string} format numbers to correct string
 */
export const currencyFormatter = (
  currency = 'USD',
  locale = 'en-US',
  minimumFractionDigits = 2
) => {
  //handle if currency passed from PMS as null
  if (currency === null) {
    currency = 'USD'
  }
  const fmt = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  })
  return (number) => {
    return fmt.format(parseFloat(number))
  }
}

import { PropertyDetailHeader } from '@/components/core/PropertyDetailHeader'
import { StarRating } from '@/components/core/StarRating'
import { useSettingContext } from '@/contexts/settings'
import { useSignalContext } from '@/contexts/signal'
import useSignalPath from '@/hooks/useSignalPath'
import { currencyFormatter } from '@/lib/currency'
import { localeFromISODate } from '@/lib/localeUtils'
import { generateBeyondImageLoader } from '@/lib/utils'
import { ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Tag,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import { IoHeart, IoHeartOutline } from 'react-icons/io5'
import { Carousel } from '../core/Carousel'
import { PropertyCardTitle } from '../core/PropertyCardTitle'
import { PropertyLocation } from '../core/PropertyLocation'
import { FlexibleModal, FlexiblePopover } from './flexible'

const DisplayImage = ({
  alt,
  carouselIndex,
  gridIndex,
  selectedImage,
  total,
  ...props
}) => {
  return (
    // Mantain desired aspect ratio
    <Box pt="66.66%" pos="relative" cursor="pointer">
      <Image
        alt={alt}
        // Eager load first 8 images as they're above the fold on desktop
        priority={gridIndex <= 8}
        layout="fill"
        objectFit="cover"
        style={{
          userSelect: 'none',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px'
        }}
        {...props}
      />
    </Box>
  )
}

export default function PropertyCard({
  property,
  gridIndex,
  favorites,
  toggleFavorites,
  ...props
}) {
  const isMobile = useBreakpointValue({ sm: true, md: false })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation(['specials', 'search'])
  const { name, images, slug, total_rent, flexible_stays } = property
  const flexibleStays = flexible_stays || []

  const { locale } = useSignalContext()
  const { settings } = useSettingContext()
  const router = useRouter()
  const [selectedImage, setSelectedImage] = React.useState(0)
  const signalPath = useSignalPath()
  const href = signalPath(`/properties/${slug}`)

  const imageSize = 'medium'

  const formatCurrency = React.useCallback(
    (amount) => {
      if (Math.floor(amount) === amount) {
        return currencyFormatter(total_rent?.currency, locale, 0)(amount)
      } else {
        return currencyFormatter(total_rent?.currency, locale, 2)(amount)
      }
    },
    [total_rent?.currency, locale]
  )

  const beyondImageLoader = generateBeyondImageLoader(settings, imageSize)
  const reviewSummary = property.reviews_summary ?? {}

  const onClickItem = React.useCallback(() => {
    router.push(href)
  }, [router, href])

  const hasTags =
    property.is_new || property.has_specials || property.is_featured

  return (
    <Box
      bg="gray.100"
      rounded="md"
      _hover={{ boxShadow: 'md' }}
      pos="relative"
      data-testid="search-property-card"
      {...props}
    >
      {hasTags && (
        <HStack pos="absolute" top={3} left={3} zIndex={2}>
          {property.is_new && (
            <Tag size="sm" fontWeight="semibold" rounded="sm">
              New
            </Tag>
          )}
          {property.has_specials && (
            <Tag size="sm" fontWeight="semibold" rounded="sm">
              Special
            </Tag>
          )}
          {property.is_featured && (
            <Tag size="sm" fontWeight="semibold" rounded="sm">
              Featured Property
            </Tag>
          )}
        </HStack>
      )}

      <HStack pos="absolute" top={3} right={3} zIndex={2}>
        {(favorites && favorites.includes(property.id) && (
          <IoHeart
            className="favorite-icon-selected"
            fontSize="1.5em"
            color="red"
            onClick={() => toggleFavorites(property)}
          />
        )) || (
          <IoHeartOutline
            className="favorite-icon"
            fontSize="1.5em"
            color="white"
            onClick={() => toggleFavorites(property)}
          />
        )}
      </HStack>

      {images.length ? (
        <Carousel
          infiniteLoop={false}
          selectedImage={selectedImage}
          showIndicators={false}
          showArrowsOnHover={true}
          onClickItem={onClickItem}
          onChange={(id) => setSelectedImage(id)}
          arrowSize={8}
        >
          {images.map((image, idx) => (
            <DisplayImage
              alt={image.alt_text}
              carouselIndex={idx}
              gridIndex={gridIndex}
              key={idx}
              loader={beyondImageLoader}
              total={images.length}
              selectedImage={selectedImage}
              src={image.full_url}
            />
          ))}
        </Carousel>
      ) : (
        <Center w="full" minH="130px" bg="gray.200" borderTopRadius="md">
          <ViewOffIcon color="gray.500" />
        </Center>
      )}
      <Box p={3} pb="4">
        <PropertyCardTitle href={href}>{name}</PropertyCardTitle>
        <PropertyDetailHeader
          property={property}
          size="sm"
          direction="column-reverse"
          spacing="0"
          pt="3"
        />
        {reviewSummary.count > 0 && (
          <HStack alignContent="center" pt="2">
            <StarRating value={Math.round(reviewSummary.rating)} />
            <Text
              fontSize="sm"
              color={reviewSummary.count ? 'gray.800' : 'gray.600'}
            >
              (
              {t('reviews', {
                ns: 'search',
                count: reviewSummary.count ?? 0
              })}
              )
            </Text>
          </HStack>
        )}
        <PropertyLocation property={property} pt="2" />
        {total_rent && !settings.hideSearchRates && (
          <Text fontSize="sm" pt="2">
            <Box as="span">Base rate: </Box>
            <Box as="span" fontWeight="semibold">
              {formatCurrency(total_rent.amount)}
            </Box>{' '}
          </Text>
        )}
        {flexibleStays.length > 0 && (
          <>
            <FlexibleModal
              slug={slug}
              flexibleStays={flexibleStays}
              isOpen={isMobile && isOpen}
              onClose={onClose}
            />
            <FlexiblePopover
              slug={slug}
              flexibleStays={flexibleStays}
              isOpen={!isMobile && isOpen}
              onClose={onClose}
            >
              <Button
                variant="link"
                size="sm"
                fontWeight="normal"
                color="wpMain.primary"
                textDecor="underline"
                onClick={onOpen}
                pt="2"
              >
                {t('alternative-dates', {
                  ns: 'search',
                  count: flexibleStays.length
                })}
              </Button>
            </FlexiblePopover>
          </>
        )}
        {property.specials?.length > 0 && (
          <>
            <Divider mt="5" mb="4" />
            <VStack alignItems="flex-start" spacing="3">
              <Text as="span" color="gray.800" fontSize="sm">
                {property.specials[0]?.description}
              </Text>
              <Text as="span" color="gray.800" fontSize="sm">
                {t('expiration-date', { ns: 'specials' })}:{' '}
                {localeFromISODate(
                  format(
                    new Date(property.specials[0]?.expiration_date),
                    'yyyy-MM-dd'
                  ),
                  locale
                )}
              </Text>
            </VStack>
          </>
        )}
      </Box>
    </Box>
  )
}

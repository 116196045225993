import { useSignalContext } from '@/contexts/signal'
import useSignalPath from '@/hooks/useSignalPath'
import { currencyFormatter } from '@/lib/currency'
import { formatDate } from '@/lib/localeUtils'
import { Button, Flex, StackDivider, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import React from 'react'

export function FlexibleStack({ slug, flexibleStays = [] }) {
  const { locale } = useSignalContext()
  const signalPath = useSignalPath()

  const { t } = useTranslation('search')
  // We assume the currency is always the same since it's the same property
  const formatCurrency = currencyFormatter(
    flexibleStays[0]?.total_rent?.currency,
    locale,
    0
  )

  return (
    <>
      <Flex direction="row-reverse" pb={4}>
        <Text fontSize="sm" color="gray.600">
          {t('alternative-options', { count: flexibleStays.length })}
        </Text>
      </Flex>
      <VStack divider={<StackDivider />} py={2}>
        {flexibleStays.map(({ checkin, checkout, total_rent }, idx) => (
          <Flex key={idx} justifyContent="space-between" w="full" py={2}>
            <Flex direction="column" fontSize={14}>
              <Text fontWeight="semibold">
                {formatDate(checkin, locale, 'PP')} &ndash;{' '}
                {formatDate(checkout, locale, 'PP')}
              </Text>
              <Text color="gray.600">
                {t('flexible-base-rate', {
                  value: formatCurrency(total_rent.amount)
                })}
              </Text>
            </Flex>
            <NextLink
              href={signalPath(`/properties/${slug}`, {
                preserve: true,
                query: {
                  checkin,
                  checkout
                }
              })}
            >
              <Button
                w={24}
                size="md"
                fontSize="sm"
                bgColor="wpMain.primary"
                _hover={{ bgColor: 'wpMain.primary' }}
                _active={{ bgColor: 'wpMain.primary', opacity: 0.8 }}
                color="white"
              >
                {t('select')}
              </Button>
            </NextLink>
          </Flex>
        ))}
      </VStack>
    </>
  )
}

import { rootId } from '@/constants'
import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import { FlexibleStack } from './FlexibleStack'

export function FlexibleModal({ slug, flexibleStays = [], isOpen, onClose }) {
  const { t } = useTranslation('search')
  const ref = React.useRef(null)

  useIsomorphicLayoutEffect(() => {
    ref.current = document.getElementById(rootId)
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      isCentered
      portalProps={{ containerRef: ref }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md" textAlign="center">
          {t('flexible-options-title')}
        </ModalHeader>
        <ModalCloseButton color="gray.500" />
        <Box px={6}>
          <Divider borderColor="gray.200" />
        </Box>
        <ModalBody>
          <FlexibleStack slug={slug} flexibleStays={flexibleStays} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

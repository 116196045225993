import { StarIcon } from '@chakra-ui/icons'
import { HStack } from '@chakra-ui/react'
import React, { useState } from 'react'

// returns e.g. [0, 1, 2, 3, 4] for n === 5
/**
 *
 * @param {number} n
 * @return {number[]}
 */
const getArrayOfNPlaceholders = (n) => {
  return [...Array(n).keys()]
}

export const StarRating = ({
  value = 0,
  starCount = 5,
  starColor = 'wpMain.primary',
  emptyStarColor = 'gray.600',
  hoverStarColor = 'wpMain.primary',
  isInteractive = false,
  newValue,
  setNewValue,
  size = 15
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1)
  const calcColor = (value, positionIndex) => {
    if (value >= positionIndex + 1) {
      return starColor
    } else if (hoveredIndex >= positionIndex && isInteractive) {
      return hoverStarColor
    } else {
      return emptyStarColor
    }
  }
  return (
    <HStack onMouseLeave={() => setHoveredIndex(-1)}>
      {getArrayOfNPlaceholders(starCount).map((key) => (
        <StarIcon
          color={calcColor(newValue || value, key)}
          h={size}
          key={key}
          w={size}
          {...(isInteractive && {
            onClick: () => setNewValue(key + 1),
            onMouseEnter: () => setHoveredIndex(key),
            sx: { '&:hover': { cursor: 'pointer' } }
          })}
        />
      ))}
    </HStack>
  )
}

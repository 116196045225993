import { CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { FlexibleStack } from './FlexibleStack'

export function FlexiblePopover({
  slug,
  flexibleStays = [],
  isOpen,
  onClose,
  children
}) {
  const { t } = useTranslation('search')

  return (
    <Popover
      placement="right"
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      isLazy
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        minW="440px"
        pt={2}
        pb={4}
        px={4}
        borderColor="wpMain.primary"
      >
        <PopoverArrow
          sx={{ '--popper-arrow-shadow-color': 'colors.wpMain.primary' }}
        />
        <PopoverCloseButton top={6} right={6}>
          <CloseIcon boxSize={4} color="gray.300" />
        </PopoverCloseButton>
        <PopoverHeader fontWeight="semibold">
          <Box as="span" display="inline-block" py={2}>
            {t('flexible-options-title')}
          </Box>
        </PopoverHeader>
        <PopoverBody>
          <FlexibleStack slug={slug} flexibleStays={flexibleStays} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
